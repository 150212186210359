<template>
  <div class="container-top">
    <div class="text-center" style="margin-top:80px" v-if="errMsg">
      <h2>[카드오류]</h2>
      <div style="margin-top:8px">오류 메세지: {{ errMsg }}</div>
      <button class="button is-primary body4-medium" style="margin-top:24px" @click="regNewCard">카드 새로 등록하기</button>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "BillDone",
    mixins: [
      UserAPIMixin
    ],
    mounted() {
      if(this.$route.query.prev) {
        setTimeout(() => {
          this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
        },100);
      }

      setTimeout(()=>{
        document.getElementById('ch-plugin').style.display = 'none';
      },1000);
      this.req();
    },
    data() {
      return {
        code: {
          '01': '하나(외환)카드',
          '03': '롯데카드',
          '04': '현대카드',
          '06': '국민카드',
          '11': 'BC카드',
          '12': '삼성카드',
          '14': '신한카드',
          '16': '농협카드',
          '21': '해외 VISA카드',
          '22': '해외마스터카드',
          '23': '해외 JCB카드',
          '26': '중국은련',
          '32': '광주은행',
          '33': '전북은행',
          '34': '하나은행',
          '35': '산업카드',
          '41': 'NH',
          '43': '씨티은행',
          '44': '우리은행',
          '48': '신협체크',
          '51': '수협',
          '52': '제주은행',
          '54': 'MG새마을금고체크',
          '55': '케이뱅크',
          '56': '카카오뱅크',
          '71': '우체국체크',
          '95': '저축은행체크'
        },
        errMsg: ''
      }
    },
    methods: {
      regNewCard() {
        let basket = this.$store.getters.basket;
        let result = JSON.parse(this.$route.query.result);
        let price = 0;
        let goodname = '';
        if(basket.total_price) {
          price = basket.price;
          goodname = basket.title;
        } else if(result.TotPrice) {
          price = result.TotPrice;
          goodname = result.goodName;
        }
        if(price > 0 && goodname) {
          this.$store.commit('setPrevPath', this.$route.fullPath);
          this.$router.push(`/bill_reg?name=${this.user.name}&phone=${this.user.phone}&email=${this.user.email}&goodname=${goodname}&price=${price}&option_type=1&type=order`);
        }
      },
      req() {
        let basket = this.$store.getters.basket;
        let result = null;
        // inicis
        if(this.$route.query.result) {
          result = JSON.parse(this.$route.query.result);
          if(!result) return;
          let resultCode = result.resultCode;
          if (resultCode !== '00' && resultCode !== '0000') {
            this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
            this.$router.replace('/service_account');
            return;
          }
        }
        // npay
        else if(this.$route.query.reserveId) {
          result = {
            reserveId: this.$route.query.reserveId,
            tempReceiptId: this.$route.query.tempReceiptId
          };
        }

        result.buyerEmail = basket.email;

        let url = '';
        let params = undefined;

        if(basket.type === 'quickbuilder') {
          this.setLoading('서비스 개설중입니다');
          url = `ui/super_builder/temporary/${basket.service.id}/service_create`;
          params = { result: result, order: basket };
        }

        // 디자인 마켓 주문 처리
        if(basket.type === 'theme') {
          this.setLoading('주문 처리중입니다');
          if(basket.hosting) basket.hosting_id = basket.hosting.id;
          if(basket.care_service) basket.care_service_id = basket.care_service.id;
          if(basket.service_use_price) basket.service_use_price_id = basket.service_use_price.id;
          url = `launchpack/service/order`;
          params = { result: result, order: basket };
        }

        this.request.user.post(url, params).then(res => {
          if (res.status === 200) {
            this.clearLoading();
            let service_key = '';
            let service_name = '';
            if(basket.type==='quickbuilder') {
              service_key = basket.service.key
              service_name = basket.service.name;
            } else if(basket.type==='theme') {
              if(res.data.key) service_key = res.data.key;
              else service_key = basket.key;
              service_name = basket.service_name;
            }
            this.$store.commit('setAccountDone', {
              id: res.data.id,
              key: service_key,
              name: service_name,
              price: basket.total_price,
              credit: `${res.data.bill.pg_type} / ${res.data.bill.card_name} ${res.data.bill.num}`,
            });
            this.$store.commit('clearBasket');
            this.routerPush('/service_account_done');
          }
        }).catch(err => {
          this.clearLoading();
          if(err.response.data.detail) {
            this.errMsg = err.response.data.detail;
          } else if(err.response.data.message) {
            this.errMsg = err.response.data.message;
          } else {
            this.toast('서버에 오류가 발생했습니다. 관리자에게 문의주세요.')
          }
        });
      },
    }
  }
</script>

<style lang="stylus" scoped>

</style>
